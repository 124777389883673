import { useSelector } from "react-redux";
import Tabs from "../Controlls/Tabs/Tabs";
import Layout from "../Layout/Layout";
import sources from '../mediaSource';

const {development, manufacturing, quality} = sources.servises;

const Services = () => {
    const activeTabID = useSelector(({ activeTabId }) => activeTabId);
    const images = [development.img, manufacturing.img, quality.img];
    const imgSrc = images[activeTabID];

    return (
        <Layout className='services'>
            <Layout.Left className='layout-left'>
                {imgSrc && <img src={imgSrc} className='mobile-page-picture' alt='block-pic' />}
                <h1 className='title'>
                    We connect all aspects of development within an integrated <span className='green'>service suite</span>
                </h1>
                <Tabs className='layout-tabs'>
                    <Tabs.Tab title='Development' icon="square">
                        <p>
                            Senfina utilized a vertically-integrated supply chain model,
                            allowing us to demonstrate our expertise in development and
                            manufacturing across the entire GMP supply chain.
                        </p>
                        <p>
                            From raw materials and non-GMP intermediates, to our massive
                            infrastructure that expands the supply chain to provide GMP
                            starting materials and manufacture active pharmaceutical
                            ingredients (APIs) and commercial-scale drug products, we are
                            strategically positioned to deliver quality products that meet
                            market demand.
                        </p>
                    </Tabs.Tab>
                    <Tabs.Tab title='Manufacturing' icon='atom'>
                        <p>
                            Senfina is a reliable partner for seamless tech transfers,
                            clinical & commercial manufacturing, aseptic fill/finish
                            and analytical testing. From the beginning of our partnership,
                            you will have a dedicated project management team to walk you
                            through the entire journey of the product development & manufacturing
                            of your product. We are committed to operational excellence in cGMP
                            manufacturing and quality assurance to deliver value to our clients.
                        </p>
                    </Tabs.Tab>
                    <Tabs.Tab title='Quality' icon="medal">
                        <p>
                            Senfina strives to provide the highest quality products and services
                            to our customers. We ensure high quality processes and facility
                            operations which are integrated as a core tenant of our business model.
                            We adhere closely to current regulatory standards and promote a quality
                            compliance culture. We take personal responsibility for upholding world
                            class quality. Our leadership endorses, recognizes, and rewards
                            excellence in quality.
                        </p>
                    </Tabs.Tab>
                </Tabs>
            </Layout.Left>
            <Layout.Right>
                <Layout.IMG videos={[development, manufacturing, quality]} loops={[[1.1, 5.2], [1.1, 5.2], [1.1, 5.2]]}/>
            </Layout.Right>
        </Layout>
    )
};

export default Services;