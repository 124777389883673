import { useSelector } from "react-redux";
import Tabs from "../Controlls/Tabs/Tabs";
import Layout from "../Layout/Layout";
import sources from '../mediaSource';

const {delivery, modular, finish} = sources.technology;

const Technology = () => {
    const activeTabID = useSelector(({ activeTabId }) => activeTabId);
    const images = [delivery.img, modular.img, finish.img];
    const imgSrc = images[activeTabID];

    return (
        <Layout className='technology'>
            <Layout.Left className='layout-left'>
                {imgSrc && <img src={imgSrc} className='mobile-page-picture' alt='block-pic' />}
                <h1 className='title'>Create next-generation therapies leveraging our suite of proprietary <span className='green'>technology</span> platforms</h1>
                <Tabs className='layout-tabs'>
                    <Tabs.Tab title='Delivery Systems' icon='truck'>
                        <p>
                            What good is a vaccine or a sterile liquid without an effective 
                            delivery system? At Senfina, we’ve taken the time to research and 
                            develop effective delivery systems that are easily usable, 
                            manufacturable, and scalable. Our injectable delivery solutions 
                            span both Terminal Sterilization and Aseptic Filling Technologies 
                            for prefilled syringes, ampoules, vials, and lyophilized vials.
                        </p>
                    </Tabs.Tab>
                    <Tabs.Tab title='Modular Process Manufacturing' icon='window'>
                        <p>
                            A modularly optimized facility allows manufacturing processes to 
                            be constructed the same way you would lay a circuit board. Production 
                            pipes and channels are integrated into the infrastructure of the facility 
                            while production lines are modular and nonstationary. This type of 
                            manufacturing layout allows systems to be efficiently and quickly 
                            patched together for agile GMP compliant process modelling.
                        </p>
                        <p>
                            Modular facilities allow for replication and operational availability, 
                            either at a single manufacturing site or multiple locations, with an 
                            additional benefit being production consistency.
                        </p>
                        <p>
                            Designing manufacturing processes through flexibility is paramount for 
                            the industry. The ability to accommodate new processing technologies 
                            or swap to different processing platforms without the need for dramatic 
                            renovation is where the industry needs to be headed to adapt to and 
                            accommodate the ever-changing and evolving product pipeline demands
                        </p>
                        <p>
                            Designing manufacturing processes through flexibility is paramount 
                            for the industry. The ability to accommodate new processing 
                            technologies or swap to different processing platforms without 
                            the need for dramatic renovation is where the industry needs to 
                            be headed to adapt to and accommodate the ever-changing and evolving 
                            product pipeline demands
                        </p>
                    </Tabs.Tab>
                    <Tabs.Tab title='Fill-and-finish solutions' icon='square'>
                        <p>
                            With central production in Switzerland, Senfina offers a 
                            “white glove” solution to fill-and-finish that combines modular 
                            process technology and Swiss Pharma Standards. In doing so, we 
                            are able to provide a scalable solution to vaccine manufacturing 
                            and global health.
                        </p>
                        <p>
                            We utilize satellite manufacturing plants that adhere to a core 
                            quality standard, thus enabling us to provide standardized fill-and-finish 
                            services that satisfy a global need for basic care, with an emphasis 
                            placed on developing and underserved regions 
                        </p>
                    </Tabs.Tab>
                </Tabs>
            </Layout.Left>
            <Layout.Right value={2}>
                <Layout.IMG videos={[delivery, modular, finish]} loops={[[1.1, 5.2], [1.1, 5.2], [1.1, 5.2]]}/>
            </Layout.Right>
        </Layout>
    );
}

export default Technology;