import cn from 'classnames';
import classes from './layout.module.scss';
import { Component, createRef, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { changeActiveTab, changeNextTab, setVideoFinishedStatus, setVideoPausedStatus } from '../reducers';

class Video extends Component {
    constructor () {
        super();
        this.video = createRef();
        this.picture = createRef();
    }

    componentDidMount () {
        const video = this.video.current;
        if (!this.props.isWebmSupported && this.picture.current) {
            this.picture.current.classList.add('anim');
            return;
        }
        
        if (!video) {
            return;
        }

        video.currentTime = 0;
        video.playbackRate = 1.3;
        video.ontimeupdate = this._onVideoUpdate;
        video.onended = this._onVideoEnd;
        video.play();
    }

    componentDidUpdate () {
        if (!this.props.isWebmSupported) {
            this.picture.current.classList.add('anim');
            return;
        }

		const video = this.video.current;
		const { isFinished, nextID, activeID, isPaused } = this.props;

        if (activeID !== nextID && isPaused && !isFinished) {
            video.play();
        }

        if (isFinished) {
            this.props.setActiveTab(nextID);
        }

        if (!isPaused && !isFinished && activeID === nextID) {
            video.play();
        }
    }

    _onVideoUpdate = e => {
        const video = e.target;
        const {activeID, nextID, loops} = this.props;
        const [start, finish] = loops[activeID];

        if (finish === 'stop' && video.currentTime >= start) {
            video.pause();
        }

        if (video.currentTime >= finish && activeID === nextID) {
            video.currentTime = start;
            // this.props.setPauseStatus(true);
        }
    }

    _onVideoEnd = () => {
        this.props.setFihishStatus(true);
    }

    _renderVideo = () => {
        const {videos, activeID} = this.props;
        const source = videos[activeID];
        let videoSRC = null;

        if (source) {
            videoSRC = source.video;
        } else {
            return null;
        }

        return (
            <div className={cn(classes.video, this.props.className)}>
                <video
                    muted
                    preload='auto'
                    ref={this.video}
                    className={classes.video_active}
                    src={videoSRC}
                >
                </video>
            </div>
        );
    }

    _renderPictures = () => {
        const {videos, activeID} = this.props;
        const source = videos[activeID];
        let imgSRC = null;

        if (source) {
            imgSRC = source.img;
        } else {
            return null;
        }
        
        return (
            <img
                src={imgSRC}
                alt='videoplug'
                className={classes.picture}
                ref={this.picture}
                onAnimationEnd={({target}) => target.classList.remove('anim')}
            />
        )
    }

    render () {
       if (this.props.isWebmSupported) {
            return this._renderVideo();
        } else {
            return this._renderPictures();
        }
    }
}



Video.defaultProps = {
    videos: [],
    looped: [],
}

const Layout = props => {
    const dispatch = useDispatch();
    const activeTabID = useSelector(({ activeTabId }) => activeTabId);
    const nextActiveID = useSelector(({ nextTabId }) => nextTabId);
    const className = cn( classes.wrapper, props.className);
    const _resetActiveTab = () => {
        if (activeTabID !== 0 || nextActiveID !== 0) {
            dispatch(changeActiveTab(0));
            dispatch(changeNextTab(0));
        }
    }
    
    useEffect(() => {
        _resetActiveTab();
        // eslint-disable-next-line
    }, []);

    return <div className={className}>{props.children}</div>;
}

Layout.Left = props => <div className={cn(classes.left, props.className)}>{props.children}</div>
Layout.Right = props => {
    return (
        <div className={cn(
            classes.right,
            {[classes.fit_content]: props.fitContent},
            props.className
        )
        }>
            {props.children}
        </div>
    )
}

const mapStateToProps = state => ({
    activeID: state.activeTabId,
    nextID: state.nextTabId,
    isPaused: state.isVideoPaused,
    isFinished: state.isVideoFinished,
    isWebmSupported: state.isWebmSupported,
});

const mapDispatchToProps = dispatch => ({
    setPauseStatus: status => dispatch(setVideoPausedStatus(status)),
    setFihishStatus: status => dispatch(setVideoFinishedStatus(status)),
    setActiveTab: id => dispatch(changeActiveTab(id)),
});

Layout.IMG = connect(mapStateToProps, mapDispatchToProps)(Video);

export default Layout;