import videoMission from './assets/video/about_mission.webm';
import imgMission from './assets/img/videoplugs/AboutUs_OurMission.png';
import videoPersonnel from './assets/video/about_personnel.webm';
import imgPersonnel from './assets/img/videoplugs/AboutUs_Personnel2.png';
import videoExecution from './assets/video/about_execution.webm';
import imgExecution from './assets/img/videoplugs/AboutUs_Execution.png';
import videoPortfolio from './assets/video/about_portfolio.webm';
import imgPortfolio from './assets/img/videoplugs/About Us_Portfolio.png';
import videoDevelopment from './assets/video/services_development.webm';
import imgDevelopment from './assets/img/videoplugs/Services_Dev.png';
import videoManufacturing from './assets/video/services_2.webm';
import imgManufacturing from './assets/img/videoplugs/Services_Manufacturing.png';
import videoQuality from './assets/video/services_quality.webm';
import imgQuality from './assets/img/videoplugs/Services_Quality.png';
import videoDelivery from './assets/video/technology_delivery.webm';
import imgDelivery from './assets/img/videoplugs/Technology_Delivery.png'
import videoModular from './assets/video/technology_modular.webm';
import imgModular from './assets/img/videoplugs/Technology_Modular.png';
import videoFinish from './assets/video/technology_finish.webm';
import imgFinish from './assets/img/videoplugs/Technology_Fill_Finish.png';
import videoBasel from './assets/video/facilities_basel.webm';
import imgBasel from './assets/img/videoplugs/Facilities_Basel.png';
import videoNigeria from './assets/video/facilities_nigeria.webm';
import imgNigeria from './assets/img/videoplugs/Facilities_Nigeria.png';
import videoBrazil from './assets/video/facilities_brazil.webm';
import imgBrazil from './assets/img/videoplugs/Facilities_Brazil.png';
import videoHome from './assets/video/home.webm';
import imgHome from './assets/img/videoplugs/home.png';

const media =  {
    home: {
        video: videoHome,
        img: imgHome
    },
    about: {
        mission: {
            video: videoMission,
            img: imgMission,
        },
        personnel: {
            video: videoPersonnel,
            img: imgPersonnel,
        },
        execution: {
            video: videoExecution,
            img: imgExecution,
        },
        portfolio: {
            video: videoPortfolio,
            img: imgPortfolio
        }
    },
    servises: {
        development: {
            video: videoDevelopment,
            img: imgDevelopment,
        },
        manufacturing: {
            video: videoManufacturing,
            img: imgManufacturing
        },
        quality: {
            video: videoQuality,
            img: imgQuality
        }
    },
    technology: {
        delivery: {
            video: videoDelivery,
            img: imgDelivery,
        },
        modular: {
            video: videoModular,
            img: imgModular
        },
        finish: {
            video: videoFinish,
            img: imgFinish
        }
    },
    facilities: {
        basel: {
            video: videoBasel,
            img: imgBasel,
        },
        nigeria: {
            video: videoNigeria,
            img: imgNigeria,
        },
        barzil: {
            video: videoBrazil,
            img: imgBrazil
        }
    }
}

export default media;