import { useHistory } from 'react-router-dom';
import Button from "../Controlls/Button/Button";
import Layout from "../Layout/Layout";
import sources from '../mediaSource';

// import '../assets/styles/pages/home.scss';

const Home = () => {
    const history = useHistory();
    return (
        <Layout className='home'>
            <Layout.Left>
                <img src={sources.home.img} className='mobile-page-picture' alt='block-pic' />
                <h1 className='title'>
                    Senfina is committed to enhancing <span className='green'>human life </span>
                    with technology and compassionate innovation</h1>
                <p className='text'>In today’s world, it’s never been more clear that the world of pharmaceuticals, medical equipment, and vaccines require more than just a few big name corporations operating within their own sphere – it requires a global effort.</p>
                <Button onClick={() => history.push('/about')}>Learn more</Button>
            </Layout.Left>
            <Layout.Right className='video-section' >
                <Layout.IMG className='video' videos={[sources.home]} loops={[[4.8, 'stop']]} />
            </Layout.Right>
        </Layout>
    )
};

export default Home;