import cn from 'classnames';
import classes from './button.module.scss';

const Button = props => (
    <button
        className={cn(
            classes.btn,
            {[classes.text]: props.variant === 'text'},
            {[classes.secondary]: props.variant === 'secondary'},
        )}
        onClick={props.onClick}
        type={props.type}
    >
        {props.children}
    </button>
);

Button.defaultProps = {
    type: 'button'
}

export default Button;