import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import classes from './navigation.module.scss';
import { useRef, useEffect} from 'react';

const routes = [
    {
        path: '/',
        name: 'Home page',
    },
    {
        path: '/about',
        name: 'About Us',
    },
    {
        path: '/services',
        name: 'Services',
    },
    {
        path: '/technology',
        name: 'Technology',
    },
    {
        path: '/facilities',
        name: 'Facilities',
    },
    {
        path: '/contact',
        name: 'Contact',
    },
];

function Navigation () {
    const {pathname} = useLocation();
    const wrapperRef = useRef();
    const dotRef = useRef();
    const activeRef = useRef();

    const _initDotPosition = () => {
        if (!activeRef.current || !dotRef.current) {
            return;
        }

        const {x} = _getLocalPosition(activeRef.current);
        const {width, heigth} = activeRef.current.getBoundingClientRect();
        dotRef.current.style.left = x + 'px';
        dotRef.current.style.width = width + 'px';
        dotRef.current.style.heigth = heigth + 'px';
    }

    useEffect(_initDotPosition, []);

    useEffect(() => {
        if (pathname === '/') {
            _initDotPosition();
        }
        // eslint-disable-next-line
    }, [pathname]);

    const _getLocalPosition = element => {
        if (!wrapperRef.current || !element) {
            return {x: 0, y: 0};
        }

        const eRect = element.getBoundingClientRect();
        const wRect = wrapperRef.current.getBoundingClientRect();

        return {
            x: Math.abs(wRect.x - eRect.x),
            y: Math.abs(wRect.y - eRect.y),
        }
    }

    const _mouseOver = e => {
         const dot = dotRef.current;
        const {x} = _getLocalPosition(e.target);
        const {width, heigth} = e.target.getBoundingClientRect();
        
        if (!activeRef.current || !dot) {
            return;
        }
        
        dot.style.left = x + 'px';
        dot.style.width = width + 'px';
        dot.style.heigth = heigth + 'px';
        dot.classList.add(classes.square);
        activeRef.current.classList.remove(classes.is_active);
        e.target.parentNode.classList.add(classes.fake_active)
    };

    const _mouseLeave = e => {
        const dot = dotRef.current;
        const {x} = _getLocalPosition(activeRef.current);
        const {width, heigth} = activeRef.current.getBoundingClientRect();

        if (!activeRef.current || !dot) {
            return;
        }

        dot.style.left = x + 'px';
        dot.style.width = width + 'px';
        dot.style.heigth = heigth + 'px';
        dot.classList.remove(classes.square);
        activeRef.current.classList.add(classes.is_active);
        e.target.parentNode.classList.remove(classes.fake_active)
    };

    const _onClick = () => {
        dotRef.current.classList.remove(classes.square);
    }

    return (
        <div className={classes.wrapper} ref={wrapperRef}>
            <div className={classes.bg} />
            <div className={classes.dot_wrapper} ref={dotRef}>
                <div className={classes.dot} />
            </div>
            <div className={classes.nav} onClick={_onClick}>
                {
                    routes.map(({name, path}) => (
                        <div
                            key={name}
                            className={cn(
                                classes.item, 
                                {[classes.is_active]: path === pathname}
                            )}
                            onMouseOver={_mouseOver}
                            onMouseLeave={_mouseLeave}
                            ref={path === pathname ? activeRef : null}
                        >
                            <Link to={path} className={classes.item_link}>
                                {name}
                            </Link>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default Navigation;