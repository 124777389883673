import { Switch, Route, Redirect } from 'react-router-dom';
import Home from './Pages/Home';
import Services from './Pages/Services';
import About from './Pages/About';
import Technology from './Pages/Technology';
import Facilities from './Pages/Facilities';
import Contact from './Pages/Contact';

const Content = () => (
    <div className='content'>
        <Switch>
            <Route exact path='/'>
                <Home />
            </Route>
            <Route path='/about'>
                <About/>
            </Route>
            <Route path='/contact'>
                <Contact />
            </Route>
            <Route path='/services'>
                <Services />
            </Route>
            <Route path='/technology'>
                <Technology />
            </Route>
            <Route path='/facilities'>
                <Facilities />
            </Route>
            <Route path='/contact'>
                <Contact />
            </Route>
            <Redirect to="/" />
        </Switch>
    </div>
);

export default Content;