import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from "../Controlls/Button/Button";
import Input from "../Controlls/Input/Input";
import Textarea from '../Controlls/Textarea/Textarea';
import Layout from "../Layout/Layout";
import { setModalOpenStatus } from '../reducers';

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [isErrorShow, setError] = useState(false);
    const dispatch = useDispatch();
    const _onSubmit = (e) => {
        e.preventDefault();
        const response = window.grecaptcha.getResponse();
        if (!response) {
            setError(true);
        } else {
            setError(false);
        }
        //todo some request
    }

    const _onLoginClick = () => {
        dispatch(setModalOpenStatus(true))
    }

    return (
        <Layout className='contact'>
            <Layout.Left className='layout-left'>
                <h1 className='title'>
                    Contact Us
                </h1>
                <p className='text'>Have a question about Senfina? Contact us today to learn more about what we do. If you’re interested in CMO, CDMO, or CPO services to accelerate your pharmaceutical manufacturing processes in the US or EU, we’re here to help.</p>
                <p className='text'>We want to help you tap into global markets, so get in touch with us today to get started.</p>
                <div className='adress'>
                    <p className='bold'>Senfina Holding Limited</p>
                    <p>Brandon House<br/>Marlowe Way<br/>Croydon , Surrey<br/>CR0 4XSUK</p>
                </div>
                <div className='login-btn'>
                    <p className='label-btn'>Shareholder Portal</p>
                    <Button variant='secondary' onClick={_onLoginClick}>Login</Button>
                </div>
            </Layout.Left>
            <Layout.Right className='layout-right'>
                <form className='form' onSubmit={_onSubmit} id='form'>
                    <Input
                        className='form-input'
                        label='Name:'
                        value={name}
                        onChange={({target}) => setName(target.value)}
                        isRequired={true}
                    />
                    <Input
                        type='email'
                        className='form-input'
                        label='Email:'
                        value={email}
                        onChange={({target}) => setEmail(target.value)}
                        isRequired={true}
                    />
                    <Input
                        type='phone'
                        className='form-input'
                        label='Phone:'
                        value={phone}
                        onChange={({target}) => setPhone(target.value)}
                        isRequired={true}
                    />
                    <Textarea
                        label='Message:'
                        value={message}
                        onChange={({target}) => setMessage(target.value)}
                        isRequired
                    />
                    <div className='form-footer'>
                        <div
                            className="g-recaptcha brochure__form__captcha"
                            data-sitekey="6Ldgdn0cAAAAAAyk25KbLoHUxZMFpAcJxRd1Q57C"
                        />  
                        <Button form='form' type='submit'>Send message</Button>
                    </div>
                    {isErrorShow && 'Are you robot?'}
                </form>
                <div className='login-btn mobile'>
                    <p className='label-btn'>Shareholder Portal</p>
                    <Button variant='secondary' onClick={_onLoginClick}>Login</Button>
                </div>
            </Layout.Right>
        </Layout>
    );
}

export default Contact;