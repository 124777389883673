import atom from '../../assets/img/icons/atom.svg';
import brazil from '../../assets/img/icons/brazil.svg';
import copy from '../../assets/img/icons/copy.svg';
import flag from '../../assets/img/icons/flag.svg';
import medal from '../../assets/img/icons/medal.svg';
import nigeria from '../../assets/img/icons/nigeria.svg';
import person from '../../assets/img/icons/person.svg';
import square from '../../assets/img/icons/square.svg';
import square2 from '../../assets/img/icons/square.svg';
import switzerland from '../../assets/img/icons/switzerland.svg';
import tool from '../../assets/img/icons/tool.svg';
import truck from '../../assets/img/icons/truck.svg';
import window from '../../assets/img/icons/window.svg';
import team from '../../assets/img/icons/team.svg';

const icons = {
    atom,
    brazil,
    copy,
    flag,
    medal,
    nigeria,
    person,
    square,
    square2,
    switzerland,
    tool,
    truck,
    window,
    team
}

export default icons;