import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModalOpenStatus } from '../reducers';
import classes from './modal.module.scss';

const Modal = props => {
    const isModalOpen = useSelector(state => state.isModalOpen);
    const dispatch = useDispatch();
    const innerRef = useRef();    

    if (!isModalOpen) {
        return null;
    }

    const _closeModal = ({target}) => {
        const inner = innerRef.current;
        if (!inner) {
            return;
        }

        if (target === inner || inner.contains(target)) {
            return;
        }

        dispatch(setModalOpenStatus(false));
    };


    return (
        <div className={classes.wrapper} onClick={_closeModal}>
            <div className={classes.inner} ref={innerRef}>
                <div className={classes.header}>
                    <h1 className={classes.title}>{props.title}</h1>
                    <div
                        className={classes.modal_close}
                        onClick={() => dispatch(setModalOpenStatus(false))} />
                </div>
                <div className={classes.body}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default Modal;