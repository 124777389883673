import Tabs from "../Controlls/Tabs/Tabs";
import cn from 'classnames';
import { useSelector } from "react-redux";
import Layout from "../Layout/Layout";
import sources from '../mediaSource';
import h1 from '../assets/img/people/ShakirMughal.jpg';
import h2 from '../assets/img/people/RayPatel.jpg';
import h3 from '../assets/img/people/AlainPralong.jpg';
import h4 from '../assets/img/people/ErhardDurrer.jpg';
import h5 from '../assets/img/people/Heringa.jpg';
import h6 from '../assets/img/people/jayaram.jpg';

const { mission, personnel, execution, portfolio } = sources.about;

const TeamMember = props => {
    return (
        <div className='team_member_wrapper'>
            <div className='team_member'>
                <img className='team_member__photo' alt='member' src={props.photo} />
                <div className='team_member_info'>
                    <p className='team_member_info__name'>{props.name}</p>
                    <p className='team_member_info__position'>{props.position}</p>
                </div>
            </div>
        </div>
    );
}

const Team = props => (
    <div className={cn('about_team', { is_mobile: props.isMobile })}>
        <h3 className='section_title'>Executive Team</h3>
        <div className='members_wrapper'>
            <TeamMember photo={h1} position='CEO' name='Shakir Mughal' />
            <TeamMember photo={h2} position='Chairman / President' name='Ray Patel' />
            <TeamMember photo={h4} position='Founding Partner' name='Erhard Durrer' />
            <TeamMember photo={h5} position='Director' name='Carin Heringa, MD' />
            <TeamMember photo={h6} position='Country Lead India and GCC' name='Jayaram Sathyanarayana' />
        </div>

    </div>
);

const About = () => {
    const activeTabID = useSelector(({ activeTabId }) => activeTabId);
    const images = [mission.img, personnel.img, execution.img, portfolio.img];
    const imgSrc = images[activeTabID];

    return (
        <Layout className='about'>
            <Layout.Left className='layout-left'>
                {imgSrc && <img src={imgSrc} className='mobile-page-picture' alt='block-pic' />}
                <h1 className='title'>A <span className='green'>solution</span> for global health is within reach</h1>
                <Tabs className='layout-tabs'>
                    <Tabs.Tab title='Our Mission' icon="flag">
                        <p>
                            As a neutral manufacturing partner, Senfina is committed to
                            continuous innovation, with emphasis placed on Personnel,
                            Execution, and Portfolio.
                        </p>
                        <p>
                            Our mission is to become the most trusted partner to our clients,
                            working together to achieve a better life for humanity through
                            strategic innovation within biomedicines
                        </p>
                    </Tabs.Tab>
                    <Tabs.Tab title='Personnel' icon='person'>
                        <p>
                            We believe that the only way to attain expertise is through knowledge,
                            and that knowledge combined with creativity and strong teamwork,
                            can achieve consistent execution of our mission. All the while,
                            Senfina is committed to demonstrating our core values of humanity,
                            integrity, passion, ownership, and dedication to each other, our clients,
                            and to society.
                        </p>
                    </Tabs.Tab>
                    <Tabs.Tab title='Execution' icon="tool">
                        <p>
                            Process Innovation through execution is how we ensure the highest quality by striving beyond “good” to “best”  manufacturing practices through client-centric innovation. Our execution excellence is enhanced by our agility,  adaptability, and speed in responding to changing global business conditions, resulting in cost competitiveness  to the benefit of our clients and for patient access to life-saving drugs.
                        </p>
                    </Tabs.Tab>
                    <Tabs.Tab title='Portfolio' icon="copy">
                        <p>
                            Portfolio Innovation drives us to continuously expand our business scope
                            and breadth of services beyond CMO to CDO and CRO, establishing global
                            reach and proximity to our clients to offer fully integrated end-to-end
                            services across an ever expanding diversified business portfolio,
                            resulting in a highly maximized, “Super Value” for our shareholders and clients.
                        </p>
                    </Tabs.Tab>
                    <Tabs.Tab title='Team' icon="team" withoutVideo>
                        <p>
                            Portfolio Innovation drives us to continuously expand our business scope
                            and breadth of services beyond CMO to CDO and CRO, establishing global
                            reach and proximity to our clients to offer fully integrated end-to-end
                            services across an ever expanding diversified business portfolio,
                            resulting in a highly maximized, “Super Value” for our shareholders and clients.
                        </p>
                        <Team isMobile />
                    </Tabs.Tab>
                </Tabs>
            </Layout.Left>
            <Layout.Right fitContent={activeTabID === 4}>
                {activeTabID === 4 ? <Team /> : (
                    <Layout.IMG
                        loops={[[1.1, 5.2], [1.1, 5.2], [1.1, 5.2], [1.1, 5.2]]}
                        videos={[mission, personnel, execution, portfolio]}
                    />
                )}
            </Layout.Right>
        </Layout>
    )
};

export default About;