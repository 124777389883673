import {useEffect, useRef} from 'react';
import classes from './textarea.module.scss';

const Textarea = props => {
    const inputRef = useRef();

    const _setHeight = () => {
        const field = inputRef.current;
        if (!field) {
            return;
        }

        field.style.height = 'inherit';
        const computed = window.getComputedStyle(field);
        const height = parseInt(computed.getPropertyValue('border-top-width'), 10)
	             + parseInt(computed.getPropertyValue('padding-top'), 10)
	             + field.scrollHeight
	             + parseInt(computed.getPropertyValue('padding-bottom'), 10)
	             + parseInt(computed.getPropertyValue('border-bottom-width'), 10);
        field.style.height = height + 'px';
    }

    useEffect(() => {_setHeight()})
    
    return (
        <label className={classes.textarea}>
            <span className={classes.label}>{props.label}</span>
            <textarea
                ref={inputRef}
                cols='10'
                className={classes.textarea_control}
                required={props.isRequired}
                value={props.value}
                onChange={props.onChange}
            >
            </textarea>
        </label>
    );
}

export default Textarea;