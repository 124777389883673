import cn from 'classnames';
import classes from './input.module.scss';

const Input = props => {
    return (
        <label className={cn(classes.input, props.className)}>
            <span className={classes.input_label}>{props.label}</span>
            <input
                className={classes.input_control}
                type={props.type}
                required={props.isRequired}
                value={props.value}
                onChange={props.onChange}
                placholdre={props.placeholder}
            />
        </label>
    );
};

export default Input;