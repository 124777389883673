import { useSelector } from "react-redux";
import Tabs from "../Controlls/Tabs/Tabs";
import Layout from "../Layout/Layout";
import sources from '../mediaSource';

const {basel, nigeria, barzil} = sources.facilities;

const Facilities = () => {
    const activeTabID = useSelector(({ activeTabId }) => activeTabId);
    const images = [basel.img, nigeria.img, barzil.img];
    const imgSrc = images[activeTabID];

    return (
        <Layout className='facilities'>
            <Layout.Left className='layout-left'>
                {imgSrc && <img src={imgSrc} className='mobile-page-picture' alt='block-pic' />}
                <h1 className='title'>A Strategic <span className='green'>Global </span>Presence</h1>
                <Tabs className='layout-tabs'>
                    <Tabs.Tab title='Basel, Switzerland ' icon='switzerland'>
                        <p>
                            With our planned 50.000m2 facility, Senfina aims to 
                            build a new state of the art production facility with 
                            integrated quality management, cell therapy and a fully 
                            automated pharmaceutical logistics infrastructure. 
                            Already now, Senfina is in negotiations for long term production 
                            agreements with leading pharmaceutical companies and governments to 
                            produce an equivalent of 1.5bn units.
                        </p>
                        <p>
                            Not only can we beat the prices that you see elsewhere on the 
                            market, but we can also ensure pharmaceutical integrity under 
                            the Swiss Quality Pharma label for your products.
                        </p>
                    </Tabs.Tab>
                    <Tabs.Tab title='Nigeria' icon='nigeria'>
                        <p>
                            In Nigeria our operations include more than 250 employees who 
                            are instrumental in the development of basic care solutions, 
                            anesthetics, and more. In addition, this facility serves as the 
                            distribution hub for highly sophisticated vaccines in the region.
                        </p>
                    </Tabs.Tab>
                    <Tabs.Tab title='Brazil' icon='brazil'>
                        <p>
                            Our efforts in Brazil are focused on the development and 
                            distribution of PFS, Pre-filled Syringes. The Facility is 
                            equipped for 500 full-time personnel.
                        </p>
                    </Tabs.Tab>
                </Tabs>
            </Layout.Left>
            <Layout.Right>
                <Layout.IMG videos={[basel, nigeria, barzil]} loops={[[1.1, 5.2], [1.1, 5.2], [1.1, 5.2]]}/>
            </Layout.Right>
        </Layout>
    )
}

export default Facilities;