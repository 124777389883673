import cn from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeActiveTab, changeNextTab } from '../../reducers';
import icons from './icons';
import classes from './tabs.module.scss';

const Tab = props => {
    const className = cn(
        classes.tab,
        {[classes.tab_active]: props.isActive},
        props.className
    );

    return (
        <div className={className} onClick={props.onClick}>
            {
                props.isActive ? props.title : 
                    <div
                        className={classes.tab_icon}
                        style={{ backgroundImage: `url(${icons[props.icon]})` }}
                    />
            }
        </div>
    );
}

const Tabs = props => {
    let activeID = useSelector(({ activeTabId }) => activeTabId);
    let nextID = useSelector(({ nextTabId }) => nextTabId);
    let isWebmSupport = useSelector(({ isWebmSupported }) => isWebmSupported);

    const contentRef = useRef();
    const dispatch = useDispatch();
    const className = cn(classes.tabs, props.className);

    useEffect(() => {
        contentRef.current.classList.add('anim');
    }, [nextID]);

    const tabButtons = React.Children.map(props.children, (child, index) => {
        return React.cloneElement(child, {
            isActive: nextID === index,
            onClick: () => {
                const previousChild = props.children[activeID];
                const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
                // eslint-disable-next-line
                if (previousChild && previousChild.props.withoutVideo || !isWebmSupport || isMobile) {
                    dispatch(changeActiveTab(index));
                } else {
                    dispatch(changeNextTab(index));
                }
            }
        });
    });

    if (activeID >= props.children.length) {
        activeID = 0;
        dispatch(changeActiveTab(0));
    }

    let child = props.children[nextID];
    if (!child) {
        child = props.children[activeID];
    }

    return (
        <div className={className}>
            <div className={classes.tabs_wrapper}>
                {tabButtons}
            </div>
            <div
                className={`${classes.tabs_content} text`}
                ref={contentRef}
                onAnimationEnd={e => {
                    e.target.classList.remove('anim');
                }}
            >
                {child.props.children}
            </div>
        </div>
    );
}

Tabs.Tab = Tab;

export default Tabs;