const video = document.createElement('video');

const initialState = {
    activeTabId: 0,
    nextTabId: 0,
    isModalOpen: false,
    isVideoPaused: false,
    isVideoFinished: false,
    isWebmSupported: video.canPlayType('video/webm; codecs="vp8, vorbis"') === '' ? false : true,
};


export const changeActiveTab = id => ({type: 'SET_NEW_TAB_ID', payload: id});
export const changeNextTab = id => ({type: 'SET_NEXT_TAB_ID', payload: id});
export const setModalOpenStatus = (isOpen = false) => ({type: 'SET_MODAL_OPEN_STATUS', payload: isOpen});
export const setVideoPausedStatus = (status = false) => ({type: 'SET_VIDEO_PAUSED_STATUS', payload: status});
export const setVideoFinishedStatus = (status = false) => ({type: 'SET_VIDEO_FINISHED_STATUS', payload: status});

export default function rootReducer (state = initialState, action) {
    if (action.type === 'SET_NEW_TAB_ID') {
        return {
            ...state,
            activeTabId: action.payload,
            nextTabId: action.payload,
            isVideoPaused: false,
            isVideoFinished: false
        };
    }

    if (action.type === 'SET_NEXT_TAB_ID') {
        return {...state, nextTabId: action.payload};
    }

    if (action.type === 'SET_MODAL_OPEN_STATUS') {
        return {...state, isModalOpen: action.payload};
    }

    if (action.type === 'SET_VIDEO_PAUSED_STATUS') {
        return {...state, isVideoPaused: action.payload};
    }

    if (action.type === 'SET_VIDEO_FINISHED_STATUS') {
        return {...state, isVideoFinished: action.payload};
    }

    return state;
}