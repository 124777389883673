import { BrowserRouter as Router} from "react-router-dom";
import Content from './Content';
import Header from './Header/Header';
import Navigation from './Navigation/Navigation';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import reducer from './reducers/index';
import './assets/styles/app.css';

const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
function App() {
    return (
        <Provider store={store}>
            <Router>
                <div className='senfina-app'>
                    <Header />
                    <Content />
                    <Navigation />
                </div>
            </Router>
        </Provider>
    );
}

export default App;
