import { useEffect, useState } from 'react';
import { NavLink, useLocation, useHistory} from 'react-router-dom';
import classNames from 'classnames';
import classes from './header.module.scss';
import Button from '../Controlls/Button/Button';
import Modal from '../Modal';
import { useDispatch } from 'react-redux';
import { setModalOpenStatus } from '../reducers';
import Input from '../Controlls/Input/Input';

const HeaderNav = () => {
    const [isOpen, setOpen] = useState(false);
    const location = useLocation();
    const className = classNames(
        classes.nav,
        {[classes.is_open]: isOpen}
    );

    useEffect(() => {
        document.querySelector('body').style.overflow = isOpen ? 'hidden' : 'initial';
    });

    useEffect(() => {setOpen(false)}, [location.pathname])

    return (
        <div className={className}>
            <div className={classes.controller} onClick={() => setOpen(!isOpen)}/>
            <div className={classes.menu}>
                <div className={classes.menu_items}>
                    <NavLink exact to='/' activeClassName={classes.active}>Home</NavLink>
                    <NavLink to='/about' activeClassName={classes.active}>About</NavLink>
                    <NavLink to='/services' activeClassName={classes.active}>Services</NavLink>
                    <NavLink to='/technology' activeClassName={classes.active}>Technology</NavLink>
                    <NavLink to='/facilities' activeClassName={classes.active}>Facilities</NavLink>
                    <NavLink to='/contact' activeClassName={classes.active}>Contact</NavLink>
                </div>
                <div className={classes.btns}>
                    <Button variant='text'>Press</Button>
                </div>
            </div>
        </div>
    );
}

const Header = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const _onSubmitLogin = e => {
        dispatch(setModalOpenStatus(false));
    }

    return (
        <>
            <Modal title='Investor login'>
                <div className={classes.login_form}>
                    <form className={classes.form} onSubmit={_onSubmitLogin}>
                        <Input
                            isRequired
                            label='Email:'
                            type='email'
                            className={classes.input_form}
                        />
                        <Input
                            isRequired
                            label="Password:"
                            type='password'
                            className={classes.input_form}
                        />
                        <Button type='submit'>Login</Button>
                    </form>
                </div>
            </Modal>
            <header className={classes.header}>
                <div className={classes.logo} onClick={() => history.push('/')} />
                <div className={classes.left_btns}>
                    <Button variant='text'>Press</Button>
                </div>
                <HeaderNav />
        </header>
        </>
    )
};

export default Header;